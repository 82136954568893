import Head from 'next/head';
import { NextSeo, ProductJsonLd, BreadcrumbJsonLd } from 'next-seo';

import { Deal } from '~/types/deal';
import {
  getProductDetailSchema,
  getTaxonomyBreadcrumbLDSchema,
  getVideoObjectLDSchemaJSONString,
} from '~/lib/util/schemaLD';

// note: removed the memo() from this component because having it memo'd was causing
// our DefaultSeo to override this product-specific SEO
export const ProductSeo = ({ deal, story }: { deal: Deal; story: any }) => {
  const productSchema = getProductDetailSchema(deal);
  const breadcrumbSchema = getTaxonomyBreadcrumbLDSchema({ deal });
  const videoSchema = getVideoObjectLDSchemaJSONString(deal);
  let title = deal.public_name;

  if (story.meta_title) {
    title += ` - ${story.meta_title}`;
  }

  const description = `${story?.card_description}. Discover the best deal for ${deal.public_name} on AppSumo today.`;

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: videoSchema }}
          key="video-schema"
        />
      </Head>
      <NextSeo
        title={title}
        description={description}
        canonical={`https://appsumo.com${deal.get_absolute_url}`}
        openGraph={{
          type: 'article',
          title,
          description: description,
          images: [
            {
              url: deal.media_url,
            },
          ],
        }}
      />
      <ProductJsonLd {...productSchema} />
      <BreadcrumbJsonLd {...breadcrumbSchema} />
    </>
  );
};
