/**
 * This file contains functions to generate JSON-LD schema for SEO. This
 * schema is used by Google to display rich results in the search results.
 *
 * By default functions return the schema in the format supported by next-seo
 * but there is an option to return the schema in the "vanilla" JSON-LD format
 * for cases where next-seo doesn't support the schema i.e. Review.
 */
import {
  BreadCrumbJsonLdProps,
  ProductJsonLdProps,
  QAPageJsonLdProps,
} from 'next-seo';
import {
  Answer,
  Person,
  Question,
  Review as ReviewNextSeo,
} from 'next-seo/lib/types';

import { Deal } from '~/types/deal';
import {
  CommentUser,
  CommentV2,
  QuestionV2,
  ReviewV2,
} from '../discussions/types';

interface BreadcrumbItem {
  name: string;
  url?: string;
}

interface PersonNextSeo extends Person {
  url: string;
}

interface QuestionNextSeo extends Question {
  datePublished: string;
  discussionUrl: string;
  isBasedOn: ProductJsonLdProps;
  author?: PersonNextSeo;
}

interface AnswerNextSeo extends Answer {
  datePublished: string;
  author?: PersonNextSeo;
}

// ported from https://github.com/django/django/blob/9bd174b9a75299dce33e673a559f2b673399b971/django/utils/html.py#L30-L52
function escapejs(str: string | undefined | null): string {
  if (!str) return '';

  const escapeMap = {
    '\\': '\\u005C',
    "'": '\\u0027',
    '"': '\\u0022',
    '>': '\\u003E',
    '<': '\\u003C',
    '&': '\\u0026',
    '=': '\\u003D',
    '-': '\\u002D',
    ';': '\\u003B',
    '`': '\\u0060',
    '\u2028': '\\u2028',
    '\u2029': '\\u2029',
  };

  let ret = '';
  for (let i = 0; i < str.length; i += 1) {
    const c = str.charAt(i);
    if (c in escapeMap) {
      // @ts-ignore
      ret += escapeMap[c];
    } else if (c.charCodeAt(0) < 32) {
      ret += `\\u${`000${c.charCodeAt(0).toString(16)}`.slice(-4)}`;
    } else {
      ret += c;
    }
  }

  return ret;
}

/**
 * Set the context and type to a schema. We need to do this because
 * next-seo doesn't have a Review component and we need to use the "vanilla"
 * JSON-LD format.
 *
 * next-seo doesn't work well if we add `@context` and `@type` in the schema.
 */
function setSchemaContext(type: string) {
  return {
    '@context': 'https://schema.org/',
    '@type': type,
  };
}

// Based on https://schema.org/Person JSON-LD structure
function getAuthorLDSchema(
  user: CommentUser,
  useNextSeoFormat = true,
): PersonNextSeo {
  let schema = {
    name: user.username,
    url: `https://appsumo.com/profile/${user.username}/`,
  };

  if (!useNextSeoFormat) {
    schema = { ...schema, ...setSchemaContext('Person') };
  }

  return schema;
}

// Based on https://schema.org/Review JSON-LD structure
function getReviewLDSchema(
  review: ReviewV2,
  useNextSeoFormat = true,
): ReviewNextSeo {
  const authorSchema = getAuthorLDSchema(review.user, useNextSeoFormat);
  let schema: ReviewNextSeo = {
    // No author type in next-seo
    // @ts-ignore
    author: useNextSeoFormat ? authorSchema.name : authorSchema,
    datePublished: review.created,
    reviewBody: escapejs(escapejs(review.comment)),
    name: escapejs(review.title),
    reviewRating: {
      ratingValue: review.rating?.toString() || '0',
      bestRating: '5',
      worstRating: '1',
    },
  };

  if (!useNextSeoFormat) {
    schema = { ...schema, ...setSchemaContext('Review') };
    schema.reviewRating = {
      ...schema.reviewRating,
      ...setSchemaContext('Rating'),
    };
  }

  // No author type in next-seo
  // @ts-ignore
  return schema;
}

// Based on https://schema.org/Product JSON-LD structure
export function getProductDetailSchema(
  deal: Deal,
  useNextSeoFormat = true,
): ProductJsonLdProps {
  const endDate = deal.dates.end_date
    ? deal.dates.end_date
    : deal.dates.schema_end_date;
  const storyImages = deal.products[0]?.story?.images || [];
  const imageUrls = storyImages.map((image) => image.image);

  let productLDSchema: ProductJsonLdProps = {
    [useNextSeoFormat ? 'productName' : 'name']: deal.public_name,

    // next-seo only support a string value for brand
    // @ts-ignore
    brand: useNextSeoFormat
      ? deal.public_name
      : {
          name: deal.public_name,
        },
    mpn: deal.id.toString(),
    sku: deal.id.toString(),
    offers: {
      priceCurrency: 'USD',
      url: `https://appsumo.com${deal.get_absolute_url}`,
      price: deal.default_price.toString(),
      availabilityStarts: deal.dates.start_date ? deal.dates.start_date : '',
      availabilityEnds: endDate,
      priceValidUntil: endDate,
      availability: deal.is_redeemable
        ? 'http://schema.org/InStock'
        : 'http://schema.org/SoldOut',
      seller: {
        name: 'AppSumo',
      },
    },
  };

  if (deal.top_5_reviews.length > 0) {
    productLDSchema.review = deal.top_5_reviews.map((x) =>
      getReviewLDSchema(x, useNextSeoFormat),
    );
  }

  if (deal.deal_review.review_count > 0) {
    productLDSchema.aggregateRating = {
      ratingValue: deal.deal_review.average_rating?.toString() || '0',
      reviewCount: deal.deal_review.review_count?.toString() || '0',
    };
  }

  if (deal.products[0]?.story?.card_description) {
    productLDSchema.description = deal.products[0]?.story?.card_description;
  }

  if (deal.media_url) {
    productLDSchema.images = [deal.media_url];
  }

  if (storyImages.length) {
    productLDSchema.images = [...(productLDSchema.images || []), ...imageUrls];
  }

  if (!useNextSeoFormat) {
    productLDSchema = { ...productLDSchema, ...setSchemaContext('Product') };
    productLDSchema.brand = {
      // next-seo only support a string value for brand
      // @ts-ignore
      ...productLDSchema.brand,
      ...setSchemaContext('Brand'),
    };
    productLDSchema.offers = {
      ...productLDSchema.offers,
      ...setSchemaContext('Offer'),
    };
    productLDSchema.offers.seller = {
      ...productLDSchema.offers.seller,
      ...setSchemaContext('Organization'),
    };

    // schema requires reviewCount to be positive value
    // so we don't include aggregateRating if no reviews
    if (deal.deal_review.review_count > 0) {
      productLDSchema.aggregateRating = {
        ...productLDSchema.aggregateRating,
        ...setSchemaContext('AggregateRating'),
      };
    }

    productLDSchema.image = [
      {
        url: deal.media_url,
        height: '266',
        width: '800',
        ...setSchemaContext('ImageObject'),
      },
      ...imageUrls,
    ];
  }

  return productLDSchema;
}

// Based on https://schema.org/Question JSON-LD structure
function getQuestionLDSchema(
  question: QuestionV2,
  deal: Deal,
): QuestionNextSeo {
  // the schema types has two Questions interfaces,
  // and for some reason next-seo doesn't add some types properly

  // @ts-ignore
  return {
    name: question?.title ?? escapejs(question.comment),
    text: escapejs(question.comment),
    answerCount: question.children?.length || 0,
    upvoteCount: question.up_votes,
    datePublished: question.created,
    author: getAuthorLDSchema(question.user),
    discussionUrl: question.display_path,
    isBasedOn: getProductDetailSchema(deal, false),
  };
}

// Based on https://schema.org/Answer JSON-LD structure
function getAnswerLDSchema(
  answer: CommentV2,
  questionPath: string,
): AnswerNextSeo {
  return {
    text: escapejs(answer.comment),
    upvoteCount: answer.up_votes,
    url: `${questionPath}${answer.id}`,
    author: getAuthorLDSchema(answer.user),
    datePublished: answer.created,
  };
}

// Based on https://schema.org/Review JSON-LD structure
// https://developers.google.com/search/docs/advanced/structured-data/review-snippet#examples
export function getReviewPageLDSchemaJSON(review: ReviewV2, deal: Deal) {
  const reviewSchema = getReviewLDSchema(review, false);

  // add info of the Product this review belongs
  // No type for itemReviewed in Review in next-seo
  // @ts-ignore
  reviewSchema.itemReviewed = getProductDetailSchema(deal, false);

  return JSON.stringify(reviewSchema);
}

export function getQuestionPageLDSchema(
  question: QuestionV2,
  deal: Deal,
): QAPageJsonLdProps {
  const questionSchema: QAPageJsonLdProps = {
    mainEntity: getQuestionLDSchema(question, deal),
  };

  if (question.children?.length) {
    questionSchema.mainEntity.suggestedAnswer = [
      ...question.children.map((answer) =>
        getAnswerLDSchema(answer, question.display_path),
      ),
    ];
  }

  return questionSchema;
}

export function getTaxonomyBreadcrumbLDSchema({
  collection,
  deal,
}: {
  collection?: Record<any, any>;
  deal: Deal;
}): BreadCrumbJsonLdProps {
  const items = [] as BreadcrumbItem[];
  const { attributes } = deal;
  const group = attributes.find((attr: any) => attr.slug === 'group');
  const category = attributes.find((attr: any) => attr.slug === 'category');
  const subcategory = attributes.find(
    (attr: any) => attr.slug === 'subcategory',
  );

  if (collection) {
    const root = collection.root_level ? '/' : '/collections';
    items.push({
      url: `https://appsumo.com${root}/${collection.slug}/`,
      name: collection.title,
    });
  } else if (group) {
    items.push({
      url: `https://appsumo.com/${group.slug}/`,
      name: group.name,
    });

    if (category) {
      items.push({
        url: `https://appsumo.com/${group.slug}/${category.slug}/`,
        name: category.name,
      });

      if (subcategory) {
        items.push({
          url: `https://appsumo.com/${group.slug}/${category.slug}/${subcategory.slug}/`,
          name: subcategory.name,
        });
      }
    }

    if (deal) {
      items.push({
        name: deal.public_name,
      });
    }
  }

  return getBreadcrumbLDSchema(items);
}

function getBreadcrumbLDSchema(items: BreadcrumbItem[]): BreadCrumbJsonLdProps {
  return {
    itemListElements: items.map((item, i) => ({
      position: i + 1,
      name: item.name,
      ...(i < items.length - 1 ? { item: item.url } : {}),
    })),
  };
}

// Based on https://schema.org/VideoObject JSON-LD structure
// next-seo only supports one video object
export function getVideoObjectLDSchema(deal: Deal) {
  const story = deal?.products?.[0].story || {};
  const { videos } = story;

  const validVideos = videos
    ?.map((video) => {
      if (video?.embed_code?.includes('youtube.com')) {
        const matches = video.embed_code.match(
          /<iframe[^>]*src=["|']([^'"]+)["|'][^>]*>/,
        );

        if (matches?.length === 2) {
          return {
            ...video,
            embed_url: matches[1],
          };
        }
      }

      return false;
    })
    .filter(Boolean);

  if (!validVideos?.length) return [];

  const storyVideoObjects = validVideos?.map((video) => ({
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    description: story.meta_title || story.card_description,
    name: deal.public_name,
    thumbnailUrl: video ? video.embed_thumbnails?.md : '',
    embedUrl: video ? video.embed_url : '',
    uploadDate: deal.dates?.start_date,
  }));

  const collectionVideoObjects =
    deal?.video_collection?.videos
      ?.filter((video) => video?.embed?.includes('youtube.com'))
      ?.map((video) => {
        const embedUrlMatches = video.embed.match(
          /<iframe[^>]*src=["|']([^'"]+)["|'][^>]*>/,
        );
        const embedUrl =
          embedUrlMatches?.length === 2 ? embedUrlMatches[1] : '';

        if (!embedUrl) return null;

        return {
          '@context': 'https://schema.org',
          '@type': 'VideoObject',
          description: video.alt,
          name: deal.public_name,
          thumbnailUrl: video.src,
          embedUrl,
          uploadDate: video.published_at,
        };
      })
      ?.filter(Boolean) || [];

  return [...storyVideoObjects, ...collectionVideoObjects];
}

export function getVideoObjectLDSchemaJSONString(deal: Deal) {
  const schema = getVideoObjectLDSchema(deal);

  if (schema) {
    return JSON.stringify(schema);
  }

  return '';
}
