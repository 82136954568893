import Head from 'next/head';
import { NextSeo } from 'next-seo';

import { ReviewV2 } from '~/lib/discussions/types';
import { Deal } from '~/types/deal';
import { getQAndRPageCanonicalUrl } from '~/lib/discussions/utils';
import { firstSentence, lastWordAt } from '~/lib/util';
import { getReviewPageLDSchemaJSON } from '~/lib/util/schemaLD';
import { DISCUSSION } from '~/lib/discussions/constants';

export function ReviewSeo({ review, deal }: { review: ReviewV2; deal: Deal }) {
  const reviewTitle = review.title || review.comment;
  const title = reviewTitle
    ? `${deal.public_name} Review: ${firstSentence({
        text: reviewTitle,
        maxLength: 60,
      })}`
    : `${deal.public_name} Review`;

  const description = lastWordAt(review.comment || review.title || '');
  const JSONLD = getReviewPageLDSchemaJSON(review, deal);

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSONLD }}
          key="review-jsonld"
        />
      </Head>
      <NextSeo
        title={title}
        description={description}
        canonical={getQAndRPageCanonicalUrl(deal.slug, DISCUSSION.REVIEWS)}
      />
    </>
  );
}
