import { NextSeo } from 'next-seo';
import useAttributes from '~/lib/attributes/hook';
import { BreadcrumbJsonLd } from 'next-seo';
import { taxonomyTitle } from '~/lib/format';
import { useMemo } from 'react';

interface BrowseSeoProps {
  group?: string;
  category?: string;
  subcategory?: string;
}

export function BrowseSeo({ group, category, subcategory }: BrowseSeoProps) {
  const { data } = useAttributes();

  let description;

  const groupAttr = data?.find((attr: any) => attr.slug === 'group');
  const categoryAttr = data?.find((attr: any) => attr.slug === 'category');
  const subcategoryAttr = data?.find(
    (attr: any) => attr.slug === 'subcategory',
  );

  const groupOpt = groupAttr?.type_options?.options.find(
    (opt: any) => opt.value === group,
  );
  const categoryOpt = categoryAttr?.type_options?.options.find(
    (opt: any) => opt.value === category,
  );
  const subcategoryOpt = subcategoryAttr?.type_options?.options.find(
    (opt: any) => opt.value === subcategory,
  );

  const breadcrumbs = useMemo(() => {
    const breadcrumbs = [];
    if (groupOpt) {
      breadcrumbs.push({
        position: 1,
        name: groupOpt.name,
        item: `/${groupOpt.value}/`,
      });

      if (categoryOpt) {
        breadcrumbs.push({
          position: 2,
          name: categoryOpt.name,
          item: `/${groupOpt.value}/${categoryOpt.value}/`,
        });

        if (subcategoryOpt) {
          breadcrumbs.push({
            position: 3,
            name: subcategoryOpt.name,
            item: `/${groupOpt.value}/${categoryOpt.value}/${subcategoryOpt.value}/`,
          });
        }
      }
    }

    return breadcrumbs;
  }, [groupOpt, categoryOpt, subcategoryOpt]);

  const title = useMemo(() => {
    let title = 'Browse software deals for your business.';

    if (groupOpt) {
      if (subcategoryOpt) {
        title = taxonomyTitle(groupOpt.name, subcategoryOpt.name);
      } else if (categoryOpt) {
        title = taxonomyTitle(groupOpt.name, categoryOpt.name);
      } else {
        title = groupOpt.name.toLowerCase();
      }

      title = `Shop ${title}`;
    }

    return title;
  }, [groupOpt, categoryOpt, subcategoryOpt]);

  return (
    <>
      <NextSeo title={title} description={description} />
      <BreadcrumbJsonLd itemListElements={breadcrumbs} />
    </>
  );
}
