import { NextSeo } from 'next-seo';

export function CollectionSeo({ collection }: { collection: any }) {
  const { title, meta_title, open_graph } = collection;

  const metaTitle = meta_title || open_graph?.title || title;
  let description = collection.subheader || '';

  if (collection.description) {
    const preText = collection.subheader;

    if (preText) {
      description = `${preText} - ${collection.description.replace(
        /<[^>]+>/g,
        '',
      )}`;
    } else {
      description = collection.description.replace(/<[^>]+>/g, '');
    }
  }

  return <NextSeo title={metaTitle} description={description} />;
}
